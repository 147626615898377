export const IconsUsed = {
  FACEBOOK_ROUND: "devicon:facebook",
  LINKEDIN_ROUND: "devicon:linkedin",
  GOOGLE_ROUND: "devicon:google",
  INSTAGRAM_ROUND: "skill-icons:instagram",
  GITHUB: "mingcute:github-fill",
  GLASSDOOR: "carbon:logo-glassdoor",
  STAR: "solar:star-bold-duotone",
  PEN: "solar:pen-bold-duotone",
  NOTEBOOK: "material-symbols:add-notes",
  EDIT: "solar:pen-bold-duotone",
  SAVE: "solar:clipboard-bold-duotone",
  BIN: "mdi:trash-can",
  COPY:"solar:copy-bold-duotone",
  SHARE:"solar:share-bold-duotone",
  LINK:"solar:link-bold-duotone",
  EMAIL: "solar:letter-opened-bold-duotone",
  PHONE: "solar:phone-calling-rounded-bold-duotone",
  PASSWORD: "solar:password-minimalistic-input-bold-duotone",
  // BUILDING: 'solar:buildings-2-bold-duotone',
  BUILDING: "heroicons:building-office-2-solid",
  LOCATION: "mdi:location",
  WEBSITE: "solar:earth-bold-duotone",
  VIEW: "solar:eye-bold-duotone",
  INFO: "solar:info-square-bold",
  CLOSE: "solar:close-circle-bold-duotone",
  CLOSE_SIMPLE: "solar:close-circle-bold",
  DOWNLOAD: "solar:download-square-bold-duotone",
  JOB: "solar:case-minimalistic-bold-duotone",
  USER: "solar:user-bold-duotone",
  USERS: "solar:users-group-two-rounded-bold-duotone",
  //USERS: "mingcute:user-2-fill",
  USER_CHECK: "solar:user-check-bold-duotone",
  USER_HEART: "solar:user-heart-bold-duotone",
  SORT_ASCENDING: "solar:double-alt-arrow-up-line-duotone",
  SORT_ASCENDING_ALT: "solar:sort-from-bottom-to-top-bold-duotone",
  SORT_DESCENDING: "solar:double-alt-arrow-down-line-duotone",
  SORT_DESCENDING_ALT: "solar:sort-from-top-to-bottom-bold-duotone",
  FILTER: "solar:filter-bold-duotone",
  TERMINAL: "solar:sidebar-code-bold-duotone",
  EDUCATION: "solar:square-academic-cap-bold-duotone",
  LAPTOP: "solar:laptop-minimalistic-bold-duotone",
  DOT_SEPARATOR: "ph:dot-duotone",
  TIME: "solar:clock-square-bold-duotone",
  CLOCK: "solar:clock-circle-bold-duotone",
  CHECK_MARK: "solar:check-circle-bold-duotone",
  WALLET: "solar:wallet-money-bold-duotone",
  CALENDAR: "solar:calendar-bold-duotone",
  SEARCH: "solar:magnifer-line-duotone",
  // UNAPPLIED: 'solar:user-rounded-bold-duotone',
  UNAPPLIED: "material-symbols:person",
  APPLIED: "solar:clipboard-check-bold-duotone",
  // SHORTLISTED: 'solar:heart-bold-duotone',
  SHORTLISTED: "lets-icons:done-ring-round-fill",
  // REJECTED: 'solar:forbidden-bold-duotone',
  REJECTED: "zondicons:close-solid",
  // APPROVED: 'solar:hand-heart-bold-duotone',
  APPROVED: "ic:baseline-verified",
  SPINNER: "solar:restart-circle-bold-duotone",
  CLOSED: "zondicons:close-solid",
  DRAFT: "solar:pause-circle-bold",
  PUBLISHED: "solar:check-circle-bold",
  EXPIRED: "solar:clock-circle-bold",
  UP: "solar:round-double-alt-arrow-up-line-duotone",
  RIGHT: "solar:round-double-alt-arrow-right-line-duotone",
  RIGHT_SQUARE: "solar:square-arrow-right-bold-duotone",
  DOT: "carbon:dot-mark",
  SUN: "solar:sun-2-bold-duotone",
  MOON: "solar:cloudy-moon-bold-duotone",
  MEDAL: "solar:medal-ribbons-star-bold-duotone",
  PLAY: "solar:play-bold",
  BELL: "solar:bell-bold-duotone",
  CROSS: "basil:cross-outline",
  WARNING: "heroicons:exclamation-circle",
  QUESTION: "material-symbols:help-outline",
  UNARCHIVE: "material-symbols:undo",
  UNDO: "material-symbols:unarchive",
  OPEN: "material-symbols:open_in_new",
  // OPEN: "solar:round-arrow-up-right-bold-duotone",
  LOCK: "solar:lock-bold-duotone",
};
